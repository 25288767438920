
import { mapActions, mapState } from "vuex"

export default {
  name: "Login",
  data: () => ({
    s3Image: process.env.S3_IMAGE,
    inputs: [
      {
        title: "Email address",
        type: "email",
        required: true,
        showTitle: false,
        name: "email",
        error: "",
        show: true,
        value: "",
        width: "100%",
        id: "email",
      },
      {
        title: "Password",
        type: "password",
        subtitle: "(must be at least 8 characters)",
        required: true,
        showTitle: false,
        name: "non-auto",
        error: "",
        show: true,
        value: "",
        autocomplete: false,
        width: "100%",
        id: "password",
      },
    ],
  }),
  computed: {
    ...mapState(["loginError", "authLoading"]),
    hasErrors() {
      return (
        this.inputs.some((input) => input.error !== "") ||
        this.inputs
          .filter((input) => input.required)
          .some((input) => input.value === "" || input.value === undefined)
      )
    },
  },
  methods: {
    ...mapActions(["logInUser"]),
    login() {
      const flatObject = {}
      this.inputs.forEach((item) => {
        flatObject[item.id] = item.value
      })
      this.logInUser({ body: flatObject, redirect: "/" })
    },
    goToSignUp() {
      this.$router.push("/sign-up")
    },
    updateLoginForm(form) {
      this.inputs = form
    },
  },
}
